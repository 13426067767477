<template>
	<div class="row">
      <div class="col-12">
         <div class="card">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-12 col-md-5 mb-3">
                     <label><i class="fas fa-barcode color-theme font-12 me-1"></i> Código</label>
                     <input type="text" class="form-control" placeholder="Informe o código" ref="codigo" v-model="codigo" v-focus @keyup.enter="buscarProduto" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-5 mb-3">
                     <label><i class="far fa-stream color-theme font-12 me-1"></i> Tipo</label>
                     <v-select :options="['Substituição', 'Acumulativo']" label="nome2" v-model="tipo" placeholder="Selecione um tipo" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-2 mb-3 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="buscarProduto">
                        <i class="fas fa-search"></i>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- modalContagem -->
      <div class="modal fade" id="modalContagem" tabindex="-1" aria-labelledby="modalContagemLabel" aria-hidden="true">
         <div class="modal-dialog modal-xxl h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalContagemLabel">Produto (Cód. {{ codigo }})</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body" v-if="contagem.info.unidadeDeMedida != undefined">
                  <div class="row m-0">
                     <div class="col-12 px-1">
                        <div class="card">
                           <div class="card-body pb-0">
                              <div class="row m-0">
                                 <div class="col-sm-6 col-md-4 px-1 mb-3">
                                    <label><i class="far fa-ruler color-theme font-12 me-1"></i> Un. Medida</label>
                                    <input type="text" class="form-control" v-model="contagem.info.unidadeDeMedida" disabled />
                                 </div>
                                 <div class="col-sm-6 col-md-3 px-1 mb-3">
                                    <label><i class="far fa-dolly-flatbed color-theme font-12 me-1"></i> Estoque atual</label>
                                    <input type="text" class="form-control" v-model="contagem.info.estoque" disabled />
                                 </div>
                                 <div class="col-md-4 px-1 mb-3" v-if="contagem.info.unidadeDeMedida == 'KG'">
                                    <label><i class="far fa-box color-theme font-12 me-1"></i> Quantidade</label>
                                    <input type="text" class="form-control" placeholder="Informe a quantidade" v-model="contagem.quantidade" ref="quantidade" v-mask="['#.###', '##.###', '###.###', '####.###', '#####.###', '######.###']" v-focus @keyup.enter="salvarContagem" />
                                 </div>
                                 <div class="col-md-4 px-1 mb-3" v-else>
                                    <label><i class="far fa-box color-theme font-12 me-1"></i> Quantidade</label>
                                    <input type="text" class="form-control" placeholder="Informe a quantidade" v-model="contagem.quantidade" ref="quantidade" v-mask="'#########'" @keyup.enter="salvarContagem" />
                                 </div>
                                 <div class="col-md-1 mb-3 px-1 align-self-end">
                                    <button type="button" class="btn btn-primary w-100" @click="salvarContagem">
                                       <i class="fas fa-check"></i>
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <produto :produto="contagem.info" />
                  </div>
               </div>
               <div class="modal-body my-5 text-center font-16" v-else>Nenhuma informação encontrada</div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarContagem" v-if="contagem.info.unidadeDeMedida != undefined">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import produto from '@/components/admin/contagem/Produto.vue'

export default {
	name: 'Contagem',
	data: function () {
		return {
			codigo: '',
         tipo: 'Substituição',
         contagem: {'quantidade': '', 'info': {}}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
         logado: state => state.logado
      })
   },
   components: {
      produto
   },
	methods : {
		buscarProduto : function () {
			if (this.codigo.length == 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Informe os campos corretamente!'
				});

				return
			}

			let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)
         this.contagem =  {'quantidade': '', 'info': {}}

         $('#modalContagem').modal('show')

			this.$axios({
				method: 'get',
				url: this.urlRest +'adminproduct/getInfoCode',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername
            },
				params: {
					codigo: this.codigo
				}
			}).then(function (response) {
            ref.contagem.info = response.data

            if (response.data.unidadeDeMedida != undefined) {
               setTimeout(() => {
                  ref.$refs.quantidade.focus()
               }, 250);
            }
			}).catch(function (error) {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						ref.$store.dispatch('deslogarAdmin')

						ref.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
                  Swal.fire({
                     icon: 'error',
                     title: 'Produto não encontrado!'
                  });
					} else {
						ref.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					ref.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
      salvarContagem : function () {
         if (this.contagem.tipo != null || parseFloat(this.contagem.quantidade) > 0) {
            let ref = this;
            this.$store.dispatch('alternarTelaCarregamento', true)

            $('#modalContagem').modal('hide')

            this.$axios({
               method: 'post',
               url: this.urlRest +'adminproduct/saveCount',
               headers: {
                  'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername
               },
               params: {
                  codigo: this.codigo,
                  quantidade: this.contagem.quantidade,
                  tipo: this.tipo
               }
            }).then(function () {
               ref.$toast.fire({
                  icon: 'success',
                  title: 'Estoque salvo!'
               });
            }).catch(function (error) {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     ref.$store.dispatch('deslogarAdmin')

                     ref.$toast.fire({
                        icon: 'warning',
                        title: 'Sessão expirada!'
                     });
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: 'Erro: ' + error.response.status
                     });
                  }
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            }).finally(() => {
               this.codigo = ''
               this.$refs.codigo.focus()

               this.$store.dispatch('alternarTelaCarregamento', false)
            });
         } else {
            $('#modalContagem').modal('hide')

            this.codigo = ''
            this.$refs.codigo.focus()
         }
      }
   }
}

</script>