
<template>
   <div class="col-12 p-0">
      <div class="card mb-2">
         <div class="card-body p-3 pe-1 d-flex">
            <div class="w-max-content align-self-center card-produto p-0 d-none d-sm-inline">
               <img class="bg-white m-0" :src="produto.foto == null ? '' : produto.foto" alt="Icon" @error="imageError">
            </div>

            <div class="col ps-sm-3 pe-sm-1 align-self-center">
               <div class="row m-0 font-14">
                  <div class="col-12 p-0 text-center mb-4">
                     <div class="limitador-2 font-18 weight-600">{{ produto.nome == null ? 'Desconhecido' : produto.nome.toUpperCase() }}</div>
                  </div>
                  <div class="col-6 col-xl-3 p-0 text-center mb-3">
                     <div class="limitador-2 text-capitalize">
                        <i class="far fa-bookmark color-theme font-20 mb-2"></i><br><strong class="me-1 text-secondary d-none d-md-inline"> Marca:</strong> 
                        {{ produto.marca == null ? 'Desconhecido' : produto.marca.toLowerCase() }}
                     </div>
                  </div>
                  <div class="col-6 col-xl-3 p-0 text-center mb-3">
                     <div class="limitador-2 text-capitalize">
                        <i class="far fa-file color-theme font-20 mb-2"></i><br><strong class="me-1 text-secondary d-none d-md-inline"> Categoria:</strong> 
                        {{ produto.categoria == null ? 'Desconhecido' : produto.categoria.toLowerCase() }}
                     </div>
                  </div>
                  <div class="col-6 col-xl-3 p-0 text-center mb-3">
                     <div class="limitador-2 text-capitalize">
                        <i class="far fa-copy color-theme font-20 mb-2"></i><br><strong class="me-1 text-secondary d-none d-md-inline"> Subategoria:</strong> 
                        {{ produto.subcategoria == null ? 'Desconhecido' : produto.subcategoria.toLowerCase() }}
                     </div>
                  </div>
                  <div class="col-6 col-xl-3 p-0 text-center mb-3">
                     <div class="limitador-2 text-capitalize">
                        <i class="far fa-shopping-cart color-theme font-20 mb-2"></i><br><strong class="me-1 text-secondary d-none d-md-inline"> Vendido:</strong> 
                        {{ produto.quantidadeVendida == null ? 0 : parseFloat(produto.quantidadeVendida).toLocaleString('pt-br', {minimumFractionDigits: 0, maximumFractionDigits:3}) }}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Produto',
   props: ['produto'],
   data: function() {
		return {
         urlAtual: window.location.hostname
		}
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>